.datagrid {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 2;
  height: 250px;
}

.action-buttons {
  margin-left: 5px;
  margin-right: 5px;
}

.refresh-button {
  float: right;
}

.back-button {
  float: right;
}

.category-chip {
  width: 20%;
}

.form-stack {
  background: #f5f5f5;
  padding: 20px 25px 30px 25px;
  border: 1px solid #ddd;
  border-radius: 10px;
  max-width: 600px;
  margin-top: 20px;
  margin-bottom: 170px;
  margin-left: auto;
  margin-right: auto;
}

.footer-action-buttons {
  width: 160px;
  max-width: 160px;
  height: 80px;
}

.onHoldModelRowsCards {
  position: relative;
  margin-left: 5px;
  padding-left: 10px;
  margin-top: 10px;
  width: 160px;
  height: 140px;
  float: left;
}

::-webkit-scrollbar {
  width: 1em;
  height: 1em;
}
::-webkit-scrollbar-button {
  background: #ccc;
}
::-webkit-scrollbar-track-piece {
  background: #ccc;
}
::-webkit-scrollbar-thumb {
  background: #eee;
}

.categories {
  max-height: 180px;
  overflow: auto;
}

.products {
  max-height: 424px;
  overflow: auto;
}

.cashRegisters {
  max-height: 324px;
  overflow: auto;
}
