.custom-file-input {
  position: absolute;
  opacity: 0;
}

.form-stack {
  background: #f5f5f5;
  padding: 20px 25px 30px 25px;
  border: 1px solid #ddd;
  border-radius: 10px;
  max-width: 600px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}
