.datagrid {
  margin-top: 20px;
  margin-bottom: 180px;
  border-radius: 2;
  height: 500px;
}

.action-buttons {
  margin-left: 5px;
  margin-right: 5px;
}

.refresh-button {
  float: right;
}

.back-button {
  float: right;
  margin-bottom: 10px;
}

.category-chip {
  width: 30%;
}

.client-form-stack {
  background: #f5f5f5;
  padding: 20px 25px 30px 25px;
  border: 1px solid #ddd;
  border-radius: 10px;
  max-width: 600px;
  margin-top: 20px;
  margin-bottom: 170px;
  margin-left: auto;
  margin-right: auto;
}
